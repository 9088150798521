import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import * as he from 'he'
import Favicon16 from '../img/cc-favicon-16.png'
import Favicon32 from '../img/cc-favicon-32.png'
import Favicon57 from '../img/cc-favicon-57.png'
import Favicon64 from '../img/cc-favicon-64.png'
import Favicon72 from '../img/cc-favicon-72.png'
import Favicon114 from '../img/cc-favicon-114.png'
import Favicon150 from '../img/cc-favicon-150.png'

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
        },
      },
    }) => {
      const seo = {
        title: he.decode(title) || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
      }

      seo.title = seo.title.replace(/&#038;/g, '&')

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <html lang="en" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
            <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
            <link rel="icon" type="image/png" sizes="64x64" href={Favicon64} />
            <link rel="apple-touch-icon" type="image/png" href={Favicon57} />
            <link
              rel="apple-touch-icon"
              type="image/png"
              sizes="72x72"
              href={Favicon72}
            />
            <link
              rel="apple-touch-icon"
              type="image/png"
              sizes="114x114"
              href={Favicon114}
            />
            <link rel="icon" type="image/png" href={Favicon150} />
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
